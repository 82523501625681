<template>
  <v-container fluid>
    <div class="row">
      <div class="col-12 col-md-7 col-lg-7 col-xl-7 pa-0">
        <Costo :type="type" />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "costoCasilleroComponent",
  props: ["type"],
  components: {
    Costo: () =>
      import("../../components/casillero/opcionCasilleroComponet.vue"),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions([]),
  },
};
</script>

<style></style>
